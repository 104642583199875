<template>
  <div class="d-flex align-center">
    <v-avatar
      size="32"
      class="me-3 me-sm-4 v-avatar-light-bg"
    >
      <v-img
        v-if="getProductDemanderImage(productDemander)"
        :src="getProductDemanderImage(productDemander)"
      ></v-img>
      <span
        v-else
        class="font-weight-medium"
      >{{ avatarText(getProductName(productDemander)) }}</span>
    </v-avatar>

    <!-- v-if="$vuetify.breakpoint.smAndUp" -->
    <div class="d-flex flex-column text-truncate">
      <div class="d-flex flex-column">
        <span class="text--primary text-base font-weight-medium me-3">
          {{ getProductCategoryName(productDemander) }}
        </span>
        <span class="text--primary text-base me-3">
          {{ getProductName(productDemander) }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    productDemander: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const getProductDemanderImage = productDemander => {
      const image = productDemander?.image || productDemander?.product?.productCategory?.image || ''

      return image
    }
    const getProductCategoryName = productDemander => productDemander?.product?.productCategory?.name || '-'
    const getProductName = productDemander => productDemander?.product?.name || 'ProductName'

    return {
      getProductDemanderImage,
      getProductCategoryName,
      getProductName,

      avatarText,
    }
  },
}
</script>
