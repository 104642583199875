import axios from '@axios'

// interface ProductSupplierInterface {
//     id: string;
//     name: string;
// }

// ProductSupplier Router list
// GET    /product/suppliers             Retrieves the collection of ProductSupplier resources.
// POST   /product/suppliers             Creates a ProductSupplier resource.
// GET    /product/suppliers/{id}        Retrieves a ProductSupplier resource.
// POST   /product/suppliers/media/{id}  Upload a ProductSupplier image. (multipart/form-data only in POST or GET)
// PUT    /product/suppliers/{id}        Replaces a ProductSupplier resource.
// DELETE /product/suppliers/{id}        Removes a ProductSupplier resource.
const routeProductSuppliers = '/product/suppliers'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// class ProductSupplier extends BaseApiService { constructor() { super(axios) }
class ProductSupplier {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchProductSuppliers(queryParams) {
    // console.log(`${this.API_BASE}${routeProductSuppliers}`)
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeProductSuppliers}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  createProductSupplier(productSupplierData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_BASE}${routeProductSuppliers}`, { ...productSupplierData })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchProductSupplier(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeProductSuppliers}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Ver CompanyService class
  updateProductSupplierMedia(id, formData) {
    return new Promise((resolve, reject) => {
      this.axiosIns

        // .post(`${this.API_BASE}${routeProductSuppliers}/media/${id}`,
        //   formData,
        //   {
        //     headers: {
        //       'Content-Type': 'multipart/form-data',
        //     },
        //   })
        .post(`${this.API_BASE}${routeProductSuppliers}/media/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateProductSupplier(productSupplierData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeProductSuppliers}/${productSupplierData.id}`, { ...productSupplierData })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  removeProductSupplier(id, isAdminView = false) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.API_BASE}${routeProductSuppliers}/${id}`, { data: { isAdminView } })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // *** Other actions //

  // Reasign ProducSupplier (Change Supplier)
  reasignProductSupplier(productSupplierId, supplierId) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeProductSuppliers}/reasign/${productSupplierId}`, { supplierId })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchProductSuppliersPublic(queryParams) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeProductSuppliers}/public`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useProductSupplier (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useProductSupplier = new ProductSupplier()
export default useProductSupplier
