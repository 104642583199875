import { mdiAccountBadgeOutline, mdiClose } from '@mdi/js'

// Estado de contacto (Ver Contact.class (Backend))
const STATUS_NEW = 'new'
const STATUS_NEGOTIATION = 'negotiation'
const STATUS_QUALIFIED = 'qualified'
const STATUS_NOT_QUALIFIED = 'not-qualified'
const STATUS_IRRELEVANT = 'irrelevant'

// TODO: Añadir el icono el color y el resolveContactStatus = (status) =>
const contactStatusOptions = [
  {
    value: STATUS_NEW,
    text: 'New',
    color: 'info',
    icon: mdiAccountBadgeOutline,
  },
  {
    value: STATUS_NEGOTIATION,
    text: 'Negociation',
    color: 'primary',
    icon: mdiAccountBadgeOutline,
  },
  {
    value: STATUS_QUALIFIED,
    text: 'Qualified',
    color: 'success',
    icon: mdiAccountBadgeOutline,
  },
  {
    value: STATUS_NOT_QUALIFIED,
    text: 'Not Qualified',
    color: 'error',
    icon: mdiAccountBadgeOutline,
  },
  {
    value: STATUS_IRRELEVANT,
    text: 'Irrelevant',
    color: 'warning',
    icon: mdiAccountBadgeOutline,
  },
]

const resolveContactStatus = status => {
  const result = contactStatusOptions.find(item => item.value === status)
  if (result) {
    return result
  }

  return {
    value: '',
    text: '',
    color: '',
    icon: mdiClose,
  }
}

// Item navegable al estado
const resolveContactRoute = status => {
  const contactStatus = resolveContactStatus(status)

  return {
    id: 'contact',
    title: 'Contact',
    value: contactStatus.value,
    text: contactStatus.text,
    icon: contactStatus.icon,
    color: contactStatus.color,
    route: {
      name: 'apps-contact-list',
      params: { },
      meta: { navActiveLink: 'chat' },
    },
    route2: { name: 'apps-contact-list' },
    badge: 0,
  }
}

const CONTACT_STATUS = {
  NEW: STATUS_NEW,
  NEGOTIATION: STATUS_NEGOTIATION,
  QUALIFIED: STATUS_QUALIFIED,
  NOT_QUALIFIED: STATUS_NOT_QUALIFIED,
  IRRELEVANT: STATUS_IRRELEVANT,
}

// export default { contactStatus }
export {
  CONTACT_STATUS,
  contactStatusOptions,
  resolveContactStatus,
  resolveContactRoute,
}
