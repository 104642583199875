import axios from '@axios'

// interface ProductDemanderInterface {
//     id: string;
//     name: string;
// }

// ProductDemander Router list
// GET    /product/demanders             Retrieves the collection of ProductDemander resources.
// POST   /product/demanders             Creates a ProductDemander resource.
// GET    /product/demanders/{id}        Retrieves a ProductDemander resource.
// POST   /product/demanders/media/{id}  Upload a ProductDemander image. (multipart/form-data only in POST or GET)
// PUT    /product/demanders/{id}        Replaces a ProductDemander resource.
// DELETE /product/demanders/{id}        Removes a ProductDemander resource.
const routeProductDemanders = '/product/demanders'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// class ProductDemander extends BaseApiService { constructor() { super(axios) }
class ProductDemander {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchProductDemanders(queryParams) {
    // console.log(`${this.API_BASE}${routeProductDemanders}`)
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeProductDemanders}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  createProductDemander(productDemanderData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_BASE}${routeProductDemanders}`, { ...productDemanderData })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchProductDemander(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeProductDemanders}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateProductDemander(productDemanderData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.API_BASE}${routeProductDemanders}/${productDemanderData.id}`, { ...productDemanderData })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  removeProductDemander(id, isAdminView = false) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.API_BASE}${routeProductDemanders}/${id}`, { data: { isAdminView } })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // *** Other actions //

  // Reasign ProducDemander (Change Demander)
  reasignProductDemander(productDemanderId, demanderId) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeProductDemanders}/reasign/${productDemanderId}`, { demanderId })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchProductDemandersPublic(queryParams) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeProductDemanders}/public`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useProductDemander (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useProductDemander = new ProductDemander()
export default useProductDemander
