<template>
  <!-- v-model="isDialogActive" -->
  <v-dialog
    :value="isDialogActive"
    fullscreen
    @click:outside="$emit('update:is-dialog-active', false)"
  >
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="red lighten-2"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Click Me
      </v-btn>
    </template> -->

    <v-card style="width: 80%;">
      <v-toolbar
        dark
        color="primary"
      >
        <div
          class="d-flex align-center cursor-pointer"
          @click="$emit('update:is-dialog-active', false);"
        >
          <v-btn
            icon
            dark
          >
            <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
          </v-btn>
          <v-toolbar-title>{{ getCompanyName(company) }}</v-toolbar-title>
        </div>

        <!-- Right: More actions -->
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
        >
          <v-icon>{{ icons.mdiDotsHorizontalCircle }}</v-icon>
        </v-btn>
      </v-toolbar>

      <CompanyHeaderCard :company="company"></CompanyHeaderCard>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <CompanyAddressCard :address="company.address"></CompanyAddressCard>
          </v-col>

          <!-- <v-col
            cols="12"
            sm="6"
          >
            <CompanyWebviewUsers
              v-if="company"
              :users-data="company.webview.users"
            ></CompanyWebviewUsers>
          </v-col> -->

          <v-col
            cols="12"
            sm="6"
          >
            <!-- Users cards -->
            <v-card
              class="rounded-0"
              outlined
            >
              <v-card-title>
                Employes
              </v-card-title>

              <v-card-text>
                <div
                  v-for="(user, index) in company.webview.users"
                  :key="index"
                >
                  <div class="d-flex align-center">
                    <user-card
                      :user="user"
                      :company="company"
                    ></user-card>

                    <!-- TODO - Acceder a ContactRelation (Podría ser global o tendrá que servirlo)-->
                    <!-- <v-spacer></v-spacer>
                    <contact-chat-message-navegable
                      :contact="contact"
                      :is-navegable="true"
                    ></contact-chat-message-navegable> -->
                  </div>
                  <v-divider
                    v-if="index != company.webview.users.length - 1"
                    class="my-1"
                  ></v-divider>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <!-- CATALOGO -->
            <v-card
              class="rounded-0 mb-4"
              outlined
              :loading="loadingProductSuppliers"
            >
              <v-card-title>
                CATALOGO
              </v-card-title>

              <v-card-text v-if="productSuppliers">
                <div
                  v-for="(productSupplier, index) in productSuppliers"
                  :key="index"
                >
                  <div class="d-flex align-center">
                    <product-supplier-card :product-supplier="productSupplier"></product-supplier-card>
                  </div>
                  <v-divider
                    v-if="index != productSuppliers.length - 1"
                    class="my-1"
                  ></v-divider>
                </div>
              </v-card-text>
            </v-card>

            <!-- PRODUCTOS DE INTERES -->
            <v-card
              class="rounded-0"
              outlined
              :loading="loadingProductDemanders"
            >
              <v-card-title>
                PRODUCTOS DE INTERES
              </v-card-title>

              <v-card-text v-if="productDemanders">
                <div
                  v-for="(productDemander, index) in productDemanders"
                  :key="index"
                >
                  <div class="d-flex align-center">
                    <product-demander-card :product-demander="productDemander"></product-demander-card>
                  </div>
                  <v-divider
                    v-if="index != productDemanders.length - 1"
                    class="my-1"
                  ></v-divider>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiArrowLeft, mdiDotsHorizontalCircle } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// import CompanyWebviewUsers from '@/views/apps/company/company-webview/CompanyWebviewUsers.vue'
// import UserCard from '@/components/Cards/UserCard.vue'
import CompanyAddressCard from '@/components/Cards/CompanyAddressCard.vue'
import CompanyHeaderCard from '../Cards/CompanyHeaderCard.vue'
import ProductDemanderCard from '../Cards/ProductDemanderCard.vue'
import ProductSupplierCard from '../Cards/ProductSupplierCard.vue'

// Services
import useProductDemander from '@/services/api/modules/productDemander'
import useProductSupplier from '@/services/api/modules/productSupplier'

export default {
  components: {
    CompanyHeaderCard,
    CompanyAddressCard,

    // CompanyWebviewUsers,
    // UserCard,
    // LINK https://v2.vuejs.org/v2/guide/components-edge-cases.html?redirect=true#Circular-References-Between-Components
    // Or alternatively, you could use Webpack’s asynchronous import when you register the component locally:
    UserCard: () => import('@/components/Cards/UserCard.vue'),

    ProductDemanderCard,
    ProductSupplierCard,
  },
  model: {
    prop: 'isDialogActive',
    event: 'update:is-dialog-active',
  },
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // Company
    const getCompanyImage = company => company?.general?.image || ''
    const getCompanyAvatar = company => company?.general?.avatar || ''
    const getCompanyName = company => company?.general?.name || ''
    const getCompanyAbout = company => company?.general?.about || 'About'
    const getCompanyProductInfo = company => company?.general?.productInfo || 'Product Info'

    // Product Suppliers
    const productSuppliers = ref([])
    const loadingProductSuppliers = ref(false)

    const fetchProductSuppliers = () => {
      loadingProductSuppliers.value = true
      productSuppliers.value = []

      useProductSupplier.fetchProductSuppliersPublic({
        companyId: props.company?.id,
        supplierId: null,
      })
        .then(response => {
          const { productSuppliers: list } = response.data
          productSuppliers.value = list
        })
        .finally(() => {
          loadingProductSuppliers.value = false
        })
    }

    // Product Demanders
    const productDemanders = ref([])
    const loadingProductDemanders = ref(false)

    const fetchProductDemanders = () => {
      loadingProductDemanders.value = true
      productDemanders.value = []

      useProductDemander.fetchProductDemandersPublic({
        companyId: props.company?.id,
        demanderId: null,
      })
        .then(response => {
          const { productDemanders: list } = response.data
          productDemanders.value = list
        })
        .finally(() => {
          loadingProductDemanders.value = false
        })
    }

    watch(
      () => props.company,
      () => {
        fetchProductSuppliers()
        fetchProductDemanders()
      },
    )
    fetchProductSuppliers()
    fetchProductDemanders()

    return {
      // Company
      getCompanyImage,
      getCompanyAvatar,
      getCompanyName,
      getCompanyAbout,
      getCompanyProductInfo,

      // CATALOGO
      productSuppliers,
      loadingProductSuppliers,

      // INTERES LIST
      productDemanders, //
      loadingProductDemanders,

      icons: {
        mdiArrowLeft,
        mdiDotsHorizontalCircle,
      },
    }
  },
}
</script>
