import {
  mdiAccountSwitchOutline,
  mdiContactsOutline,
} from '@mdi/js'

// Notification
import { POSITION, TYPE } from 'vue-toastification'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@/components/ToastificationContent.vue'

// Dependencies
import { contactStatusOptions, resolveContactStatus } from '@/services/master/ContactStatus'
import { userStatusOptions, resolveUserStatus } from '@/services/master/UserStatus'

export default function useContactHandler() {
  // Esto es para traducir las constantes (Textos fijos como Roles, Estados, ...)
  // const { t, te } = useI18nUtils()
  const toast = useToast()

  // ------------------------------------------------
  // (UI) Notification
  // ------------------------------------------------
  const sendContactNotification = (title, variant = TYPE.ERROR, icon = mdiContactsOutline) => {
    // toast.warning(`Product Supplier ${action}`)
    toast(
      {
        component: ToastificationContent,
        props: {
          title: `Contact - ${title}`,
          variant,
          icon,
        },
      },
      { position: POSITION.BOTTOM_CENTER },
    )
  }

  // CUSTOM ACTIONS
  const customActionReasign = {
    title: 'Reasign',
    value: 'reasign',
    color: 'success',
    icon: mdiAccountSwitchOutline,
  }

  return {
    // UI
    sendContactNotification,
    customActionReasign,

    // User
    userStatusOptions,
    resolveUserStatus,

    // Contact
    contactStatusOptions,
    resolveContactStatus,
  }
}
