<template>
  <!-- v-model="isDialogActive" -->
  <v-dialog
    :value="isDialogActive"
    fullscreen
    @click:outside="$emit('update:is-dialog-active', false)"
  >
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="red lighten-2"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Click Me
      </v-btn>
    </template> -->

    <v-card style="width: 80%;">
      <v-toolbar
        dark
        color="primary"
      >
        <div
          class="d-flex align-center cursor-pointer"
          @click="$emit('update:is-dialog-active', false);"
        >
          <v-btn
            icon
            dark
          >
            <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
          </v-btn>
          <v-toolbar-title>{{ getUserName(user) }}</v-toolbar-title>
        </div>

        <!-- Right: More actions -->
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
        >
          <v-icon>{{ icons.mdiDotsHorizontalCircle }}</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Header: Company(Left) User(Right) -->
      <UserHeaderCard
        :user="user"
        :company="company"
      ></UserHeaderCard>

      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <CompanyAddressCard :address="company.address"></CompanyAddressCard>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <!-- CATALOGO -->
            <v-card
              v-if="user.supplierId"
              class="rounded-0 mb-4"
              outlined
              :loading="loadingProductSuppliers"
            >
              <v-card-title
                class="success"
                style="color: white !important"
              >
                CATALOGO (Vendedor)
              </v-card-title>

              <v-card-text v-if="productSuppliers">
                <div
                  v-for="(productSupplier, index) in productSuppliers"
                  :key="index"
                >
                  <div class="d-flex align-center">
                    <product-supplier-card :product-supplier="productSupplier"></product-supplier-card>
                  </div>
                  <v-divider
                    v-if="index != productSuppliers.length - 1"
                    class="my-1"
                  ></v-divider>
                </div>
              </v-card-text>
            </v-card>

            <!-- PRODUCTOS DE INTERES -->
            <v-card
              v-if="user.demanderId"
              class="rounded-0"
              outlined
              :loading="loadingProductDemanders"
            >
              <v-card-title
                class="primary"
                style="color: white !important"
              >
                PRODUCTOS DE INTERES (Comprador)
              </v-card-title>

              <v-card-text v-if="productDemanders">
                <div
                  v-for="(productDemander, index) in productDemanders"
                  :key="index"
                >
                  <div class="d-flex align-center">
                    <product-demander-card :product-demander="productDemander"></product-demander-card>
                  </div>
                  <v-divider
                    v-if="index != productDemanders.length - 1"
                    class="my-1"
                  ></v-divider>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiArrowLeft, mdiDotsHorizontalCircle } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

import UserHeaderCard from '../Cards/UserHeaderCard.vue'
import CompanyAddressCard from '@/components/Cards/CompanyAddressCard.vue'
import ProductDemanderCard from '../Cards/ProductDemanderCard.vue'
import ProductSupplierCard from '../Cards/ProductSupplierCard.vue'

// Services
import useProductDemander from '@/services/api/modules/productDemander'
import useProductSupplier from '@/services/api/modules/productSupplier'

export default {
  components: {
    UserHeaderCard,
    CompanyAddressCard,
    ProductDemanderCard,
    ProductSupplierCard,
  },
  model: {
    prop: 'isDialogActive',
    event: 'update:is-dialog-active',
  },
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // Company
    const getCompanyImage = company => company?.general?.image || ''
    const getCompanyAvatar = company => company?.general?.avatar || ''
    const getCompanyName = company => company?.general?.name || ''
    const getCompanyAbout = company => company?.general?.about || 'About'
    const getCompanyProductInfo = company => company?.general?.productInfo || 'Product Info'

    // Contact
    const getUserAvatar = user => user?.avatar || ''
    const getUserStatus = user => user?.status || ''
    const getUserName = user => (user ? `${user?.firstName ?? ''} ${user?.lastName ?? ''}` : '')
    const getUserAbout = user => user?.about || ''
    const getUserCompanyRole = user => user?.companyRole || ''

    // Product Suppliers
    const productSuppliers = ref([])
    const loadingProductSuppliers = ref(false)

    const fetchProductSuppliers = () => {
      if (!props.user?.supplierId) {
        return
      }

      loadingProductSuppliers.value = true
      productSuppliers.value = []

      useProductSupplier.fetchProductSuppliersPublic({
        companyId: null,
        supplierId: props.user?.supplierId,
      })
        .then(response => {
          const { productSuppliers: list } = response.data
          productSuppliers.value = list
        })
        .finally(() => {
          loadingProductSuppliers.value = false
        })
    }

    // Product Demanders
    const productDemanders = ref([])
    const loadingProductDemanders = ref(false)

    const fetchProductDemanders = () => {
      if (!props.user?.demanderId) {
        return
      }
      loadingProductDemanders.value = true
      productDemanders.value = []

      useProductDemander.fetchProductDemandersPublic({
        companyId: null,
        demanderId: props.user?.demanderId,
      })
        .then(response => {
          const { productDemanders: list } = response.data
          productDemanders.value = list
        })
        .finally(() => {
          loadingProductDemanders.value = false
        })
    }

    watch(
      () => props.user,
      () => {
        fetchProductSuppliers()
        fetchProductDemanders()
      },
    )
    fetchProductSuppliers()
    fetchProductDemanders()

    return {
      // Company
      getCompanyImage,
      getCompanyAvatar,
      getCompanyName,
      getCompanyAbout,
      getCompanyProductInfo,

      // Contact
      getUserAvatar,
      getUserName,
      getUserStatus,
      getUserAbout,
      getUserCompanyRole,

      // CATALOGO
      productSuppliers,
      loadingProductSuppliers,

      // INTERES LIST
      productDemanders, //
      loadingProductDemanders,

      icons: {
        mdiArrowLeft,
        mdiDotsHorizontalCircle,
      },
    }
  },
}
</script>

<!-- <style lang="scss" scoped>

  .avatar-center {
    top: -2rem;
    left: 1rem;
    border: 3px solid white;
    position: absolute;
  }

</style> -->
