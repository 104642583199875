<template>
  <div>
    <!-- Dialog Contact: Ver detalle del proveedor -->
    <!-- Usamos v-if para que no carge el componente varias veces -->
    <CompanyDialog
      v-if="isCompanyDialogActive"
      v-model="isCompanyDialogActive"
      :company="company"
    />

    <!-- Dialog Contact: Ver detalle del proveedor -->
    <!-- Usamos v-if para que no carge el componente varias veces -->
    <UserDialog
      v-if="isUserDialogActive"
      v-model="isUserDialogActive"
      :user="user"
      :company="company"
    />

    <!-- ContactCard content -->
    <div
      v-if="big"
      class="text-center px-6 pt-4"
    >
      <v-badge
        bottom
        overlap
        bordered
        :color="resolveUserStatus(getUserStatus(user)).color"
        offset-x="24"
        offset-y="18"
        class="mb-5"
      >
        <v-avatar
          size="80"
          :class="`v-avatar-light-bg ${resolveUserStatus(getUserStatus(user)).color}--text`"
        >
          <v-img
            v-if="getUserAvatar(user)"
            :src="getUserAvatar(user)"
          ></v-img>
          <span
            v-else
            class="text-3xl"
          >{{ avatarText(getUserName(user)) }}</span>
        </v-avatar>
      </v-badge>
      <!-- Name + Company -->
      <div class="d-flex flex-column text-truncate">
        <div class="d-flex flex-column">
          <p class="text-capitalize mb-0">
            <span
              class="text--primary text-base font-weight-medium me-3 text-underline-hover cursor-pointer"
              @click.stop="(isUserDialogActive = true)"
            >
              {{ getUserName(user) }}
            </span>
            <span>{{ getUserAbout(user) }}</span>
          </p>
          <p class="text-capitalize mb-0">
            <span
              class="text--primary text-base me-3 text-underline-hover cursor-pointer"
              @click.stop="(isCompanyDialogActive = true)"
            >
              {{ getCompanyName(company) }}
            </span>
            <span>({{ getUserCompanyRole(user) }})</span>
          </p>
        </div>
      </div>
    </div>
    <!-- Inline format -->
    <div
      v-else
      class="d-flex align-center"
      :style="styleObject"
    >
      <!-- Avatar(40) + dot(N): x28 y15 -->
      <!-- Avatar(32) + dot(Y): x9  y9 -->
      <v-badge
        bottom
        overlap
        bordered
        :color="resolveUserStatus(getUserStatus(user)).color"
        offset-x="9"
        offset-y="9"
        dot
        class="me-3 me-sm-4"
      >
        <v-avatar
          size="32"
          :class="`v-avatar-light-bg ${resolveUserStatus(getUserStatus(user)).color}--text`"
        >
          <v-img
            v-if="getUserAvatar(user)"
            :src="getUserAvatar(user)"
          ></v-img>
          <span
            v-else
            class="font-weight-medium"
          >{{ avatarText(getUserName(user)) }}</span>
        </v-avatar>
      </v-badge>
      <!-- Name + Company -->
      <div class="d-flex flex-column text-truncate">
        <div class="d-flex flex-column">
          <span
            class="text--primary text-base font-weight-medium me-3 text-underline-hover cursor-pointer"
            @click.stop="(isUserDialogActive = true)"
          >
            {{ getUserName(user) }}
          </span>
          <span
            class="text--primary text-base me-3 text-underline-hover cursor-pointer"
            @click.stop="(isCompanyDialogActive = true)"
          >
            {{ getCompanyName(company) }}
          </span>
        </div>
      </div>
    </div>
    <!-- // Contact content Small -->
  </div>
</template>
<script>
import { avatarText } from '@core/utils/filter'
import { ref, reactive } from '@vue/composition-api'

// Services
import useContactHandler from '@/views/apps/chat/useContactHandler'

// Components
import CompanyDialog from '@/components/Dialog/CompanyDialog.vue'
import UserDialog from '../Dialog/UserDialog.vue'

export default {
  components: {
    CompanyDialog,
    UserDialog,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
    big: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: null, // null or undefined
    },
  },
  setup(props) {
    const { resolveUserStatus } = useContactHandler()

    const isCompanyDialogActive = ref(false)
    const isUserDialogActive = ref(false)

    // Contact
    const getUserAvatar = user => user?.avatar || ''
    const getUserStatus = user => user?.status || ''
    const getUserName = user => (user ? `${user?.firstName ?? ''} ${user?.lastName ?? ''}` : '')
    const getUserAbout = user => user?.about || ''
    const getUserCompanyRole = user => user?.companyRole || ''

    // Company
    const getCompanyName = company => company?.general?.name || ''

    // Set style if indicate
    const styleObject = reactive({
      width: props.width,
      'max-width': props.width,
    })

    return {
      // UI
      resolveUserStatus,
      avatarText,
      styleObject,

      // Data
      isCompanyDialogActive,
      isUserDialogActive,

      // Contact
      getUserAvatar,
      getUserName,
      getUserStatus,
      getUserAbout,
      getUserCompanyRole,

      // Company
      getCompanyName,
    }
  },
}
</script>
<style lang="scss" scoped>
.text-underline-hover {
  text-decoration: none;
}

.text-underline-hover:hover {
  text-decoration: underline;
}
</style>
