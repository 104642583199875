<template>
  <div
    class="d-flex align-center"
    :style="styleObject"
  >
    <!-- :color="resolveProductSupplierStatus(item.status).color" -->
    <!-- :class="`v-avatar-light-bg ${resolveProductSupplierStatus(item.status).color}--text me-3`" -->
    <v-avatar
      size="32"
      class="me-3 me-sm-4 v-avatar-light-bg"
    >
      <v-img
        v-if="getProductSupplierImage(productSupplier)"
        :src="getProductSupplierImage(productSupplier)"
      ></v-img>
      <span
        v-else
        class="font-weight-medium"
      >{{ avatarText(getProductName(productSupplier)) }}</span>
    </v-avatar>

    <!-- v-if="$vuetify.breakpoint.smAndUp" -->
    <div class="d-flex flex-column text-truncate">
      <div class="d-flex flex-column">
        <span class="text--primary text-base font-weight-medium me-3">
          {{ getProductCategoryName(productSupplier) }}
        </span>
        <span class="text--primary text-base me-3">
          {{ getProductName(productSupplier) }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    productSupplier: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: null, // null or undefined
    },
  },
  setup(props) {
    const getProductSupplierImage = productSupplier => {
      const image = productSupplier?.image || productSupplier?.product?.productCategory?.image || ''

      return image
    }
    const getProductCategoryName = productSupplier => productSupplier?.product?.productCategory?.name || '-'
    const getProductName = productSupplier => productSupplier?.product?.name || 'ProductName'

    // Set style if indicate
    const styleObject = reactive({
      width: props.width,
      'max-width': props.width,
    })

    return {
      getProductSupplierImage,
      getProductCategoryName,
      getProductName,

      avatarText,
      styleObject,
    }
  },
}
</script>
