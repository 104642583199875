<template>
  <v-card>
    <v-card-title>
      {{ $t('CompanySettings.body.tab4.address.title') }}
    </v-card-title>

    <v-card-text>
      <div class="d-flex align-center">
        <v-icon
          size="20"
          class="mt-n4"
        >
          {{ icons.mdiMapMarkerOutline }}
        </v-icon>
        <div class="ms-2">
          <p class="text--primary mb-0">
            {{ address.street }}
          </p>
          <span class="text-xs">
            {{ address.region }} - {{ address.city }} {{ address.zip }} {{ address.country }}
          </span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiMapMarkerOutline,
} from '@mdi/js'

export default {
  props: {
    address: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      icons: {
        mdiMapMarkerOutline,
      },
    }
  },
}
</script>
