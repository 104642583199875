import {
  mdiAccountBadgeOutline,
  mdiAccountClockOutline,
  mdiAccountCogOutline,
  mdiAccountCancelOutline,
  mdiClose,
} from '@mdi/js'

// Estado de usuario (Ver User.class (Backend))
const STATUS_ONLINE = 'online'
const STATUS_BUSY = 'busy'
const STATUS_AWAY = 'away'
const STATUS_OFFLINE = 'offline'

const userStatusOptions = [
  {
    value: STATUS_ONLINE,
    text: 'On line',
    color: 'success',
    icon: mdiAccountBadgeOutline,
  },
  {
    value: STATUS_BUSY,
    text: 'Busy',
    color: 'error',
    icon: mdiAccountClockOutline,
  },
  {
    value: STATUS_AWAY,
    text: 'Away',
    color: 'warning',
    icon: mdiAccountCogOutline,
  },
  {
    value: STATUS_OFFLINE,
    text: 'Off line',
    color: 'secondary',
    icon: mdiAccountCancelOutline,
  },
]

// Translate UserStatus options
// User.Fields.status-options.online ==> On line
// User.Fields.status-options.busy ==> Busy
// User.Fields.status-options.away ==> Away
// User.Fields.status-options.offline ==> Off line
// const userStatusOptions = userStatusList.map(userStatus => ({ value: userStatus.value, text: t(`User.Fields.status-options.${userStatus.value}`) }))
// const userStatusOptions = userStatusList.map(userStatus => ({ value: userStatus.value, text: userStatus.text }))

const resolveUserStatus = status => {
  const result = userStatusOptions.find(item => item.value === status)
  if (result) {
    return result
  }

  return {
    value: '', text: '', color: '', icon: mdiClose,
  }
}

const USER_STATUS = {
  ONLINE: STATUS_ONLINE,
  BUSY: STATUS_BUSY,
  AWAY: STATUS_AWAY,
  OFFLINE: STATUS_OFFLINE,
}

// export default { userStatus }
export {
  USER_STATUS,
  userStatusOptions,
  resolveUserStatus,
}
