<template>
  <v-card>
    <v-img
      class="white--text align-end"
      :src="getCompanyImage(company)"
      :alt="getCompanyName(company)"
      height="250"
    />

    <v-card-text class="d-flex justify-space-between">
      <!-- Left: Company -->
      <div class="d-flex flex-column flex-sm-row">
        <v-avatar
          tile
          size="120"
          color="white"
          class="avatar-center"
        >
          <v-img
            :src="getCompanyAvatar(company)"
            rounded
            fluid
          ></v-img>
        </v-avatar>
        <div class="d-flex flex-column align-start ml-2- pt-4">
          <v-card-title class="pt-0 px-0">
            {{ getCompanyName(company) }}
          </v-card-title>
          <v-card-subtitle class="pa-0">
            {{ getCompanyAbout(company) }}
          </v-card-subtitle>
          <v-card-subtitle class="pa-0">
            {{ getCompanyProductInfo(company) }}
          </v-card-subtitle>
        </div>
      </div>

      <!-- Right: Contact -->
      <div class="d-flex flex-column flex-sm-row-reverse">
        <v-avatar
          tile
          size="120"
          color="white"
          class="avatar-center"
        >
          <v-img
            :src="getUserAvatar(user)"
            rounded
            fluid
          ></v-img>
        </v-avatar>
        <div class="d-flex flex-column align-end mr-2- pt-4">
          <v-card-title class="pt-0 px-0">
            {{ getUserName(user) }}
          </v-card-title>
          <v-card-subtitle class="pa-0">
            {{ getUserAbout(user) }}
          </v-card-subtitle>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiArrowLeft,
  mdiDotsHorizontalCircle,
} from '@mdi/js'

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // Company
    const getCompanyImage = company => company?.general?.image || ''
    const getCompanyAvatar = company => company?.general?.avatar || ''
    const getCompanyName = company => company?.general?.name || ''
    const getCompanyAbout = company => company?.general?.about || 'About'
    const getCompanyProductInfo = company => company?.general?.productInfo || 'Product Info'

    // Contact
    const getUserAvatar = user => user?.avatar || ''
    const getUserStatus = user => user?.status || ''
    const getUserName = user => (user ? `${user?.firstName ?? ''} ${user?.lastName ?? ''}` : '')
    const getUserAbout = user => user?.about || ''
    const getUserCompanyRole = user => user?.companyRole || ''

    return {
      // Company
      getCompanyImage,
      getCompanyAvatar,
      getCompanyName,
      getCompanyAbout,
      getCompanyProductInfo,

      // Contact
      getUserAvatar,
      getUserName,
      getUserStatus,
      getUserAbout,
      getUserCompanyRole,

      icons: {
        mdiArrowLeft,
        mdiDotsHorizontalCircle,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

  .avatar-center {
    margin-top: -2rem;
    border: 3px solid white;
  }

</style>
